<template>
  <div class="text-end" style="color:rgba(94, 86, 105, 0.87) !important">
    <div>
      <div v-for="(bonus, index) in localBonusesAndFees" :key="bonus.id">
        <bonus-row v-model="localBonusesAndFees[index]" @removeBonus="removeBonus" @input="saveBonuses" />
      </div>

      <v-btn id="addBonusOrFeeButton" outlined color="primary" @click.prevent="addBonus" v-if="canEdit">
        <v-icon class="mr-3" small color="primary">add</v-icon>

        BONUS OR FEE
      </v-btn>
    </div>
  </div>
</template>

<script>
import BonusRow from '@/pages/commissions/commission-statements/wizard/steps/producer/BonusRow'
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: { BonusRow },
  props: [],
  data() {
    return {
      localBonusesAndFees: [],
      loading: {
        bonuses: false,
      },
    }
  },
  methods: {
    ...mapActions({
      updateBonusesAndFees: 'commissions/producerStatement/updateBonusesAndFees',
    }),
    addBonus() {
      this.localBonusesAndFees.push({
        id: Math.random(),
        label: 'Agency Revenue',
        other: '',
        value: 0,
        rate: 0,
        mode: 1,
      })
    },
    saveBonuses: _.debounce(function() {
      this.$debug.info('saving bonuses')

      let bonuses = this.localBonusesAndFees

      if (bonuses.length === 0) {
        bonuses = null
      } else {
        bonuses = this.$_.map(bonuses, bonus => {
          return {
            id: bonus.id,
            label: bonus.label === 'Other' ? bonus.other : bonus.label,
            other: bonus.other,
            value: bonus.value,
            rate: bonus.rate,
            mode: bonus.mode,
          }
        })
      }

      this.$debug.info(bonuses)

      this.updateBonusesAndFees({
        bonusesAndFees: bonuses,
      })
        .then(({ data }) => {
          this.loading.bonuses = false

          this.$debug.info('saved bonuses')
        })
        .catch(error => {
          this.$debug.info('error saving bonuses')
          this.$debug.info(error)
          this.$debug.info(error.response)
          this.loading.bonuses = false
        })
    }, 500),
    removeBonus(bonus) {
      let index = this.localBonusesAndFees.indexOf(bonus)
      this.$debug.info('Removing bonus with index: ' + index)
      this.localBonusesAndFees.splice(index, 1)

      this.saveBonuses()
    },
  },
  mounted() {
    this.localBonusesAndFees = this.$_.cloneDeep(this.bonusesAndFees)
  },
  watch: {
    bonusesAndFees: {
      deep: true,
      handler() {
        this.localBonusesAndFees = this.$_.cloneDeep(this.bonusesAndFees)
      },
    },
  },
  computed: {
    ...mapGetters({
      subtotal: 'commissions/producerStatement/getSubtotal',
      bonusesAndFees: 'commissions/producerStatement/getBonusesAndFees',
      bonusesAndFeesTotal: 'commissions/producerStatement/getBonusesAndFeesTotal',
      isFinalized: 'commissions/monthlyReview/getFinalized',
    }),
    bonusesAndFeesTotalFormatted() {
      return this.bonusesAndFees.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    canEdit() {
      return this.isFinalized === false
    },
  },
}
</script>

<style lang="scss" scoped>
#addBonusOrFeeButton {
  ::v-deep .v-v-btn__content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  padding: 9px, 16px, 9px, 16px;

  margin-top: 18px;
}
</style>
