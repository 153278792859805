<template>
  <div>
    <div id='statementContentHeaderContainer'>
      <div class='d-flex justify-space-between'>
        <h3 id='statementContentContainerTitle'>
          <span class='stepNumber'>{{ '0' + activeStep }}</span>
          Approve Producer Pay
        </h3>
        <div>
          <approve-producer-button @approved='goToNext' />
        </div>
      </div>

      <div class='flex-grow-1' id='filterPanel'>
        <div class='d-flex flex-wrap'>
          <div class='flex-grow-1 mr-3 ' style='max-width: 250px'>
            <span class='filterSelectTitle'>Report For:</span>
            <producer-select />
          </div>
          <div class='flex-grow-1 mr-3 ' style='max-width: 250px'>
            <span class='filterSelectTitle'>Pay Period:</span>
            <wizard-date-picker @goToStep='goToStep'/>
          </div>
<!--          <div class='flex-grow-1' style='max-width: 250px'>-->
<!--            <span class='filterSelectTitle'>Pay Period:</span>-->


<!--            <div>-->
<!--&lt;!&ndash;              <v-col class='mt-3'>&ndash;&gt;-->
<!--&lt;!&ndash;                <v-icon class='ba-primary&#45;&#45;text' style='margin-right:10px;'>&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ icons.mdiCalendar }}&ndash;&gt;-->
<!--&lt;!&ndash;                </v-icon>&ndash;&gt;-->

<!--&lt;!&ndash;                <span class='dateText'>{{ month }} {{ year }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              </v-col>&ndash;&gt;-->
<!--            </div>-->

        </div>
      </div>
    </div>

    <div id='tablePanel'>
      <producer-commissions-table :loading='loading.statement'
                                  @commissionsUpdated='refreshStatement' />
    </div>
    <div id='bonusPanel'>
      <bonus-editor :key='$route.path' />
    </div>
    <div id='subTotalPanel'>
      <subtotal-panel />
    </div>
    <div id='totalPanel'>
      <total-panel />
    </div>

    <deferred-commissions-table :user='activeUser' :activeDate='activeDate' :key='tableData.length'
                                v-if='!loading.statement' @reload='loadStatement({
        producerId:$route.params.producer,
        date: date,
      })' />
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BonusEditor from './producer/BonusEditor.vue'
import producerCommissionsTable from './producer/ProducerCommissionsTable'
import ProducerSelect from './producer/ProducerSelect.vue'
import SubtotalPanel from './producer/SubtotalPanel.vue'
import TotalPanel from './producer/TotalPanel.vue'
import ApproveProducerButton from './producer/ApproveProducerButton.vue'
import DeferredCommissionsTable
  from '@/pages/commissions/commission-statements/wizard/steps/producer/DeferredCommissionsTable.vue'
import WizardDatePicker
  from '@/pages/commissions/commission-statements/wizard/steps/components/WizardDatePicker.vue'

export default {
  props: ['activeStep'],
  components: {
    DeferredCommissionsTable,
    producerCommissionsTable,
    BonusEditor,
    ProducerSelect,
    SubtotalPanel,
    TotalPanel,
    ApproveProducerButton,
    WizardDatePicker,
  },
  data() {
    return {
      icons: {
        mdiCalendar,
      },
      loading: {
        download: false,
        statement: true,
      },
    }
  },
  methods: {
    ...mapActions({
      loadStatement: 'commissions/producerStatement/loadStatement',
      loadProducers: 'commissions/producers/loadProducers',
    }),
    ...mapMutations({
      setStepsStatuses: 'commissions/monthlyReview/setStepsStatuses',
    }),
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023, parseInt(monthNumber) - 1, 1)

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },
    refreshStatement() {
      this.loading.statement = true
      this.loadStatement({
        producerId: this.$route.params.producer,
        date: this.date,
      }).then(() => {
        this.loading.statement = false
      })
    },
    goToNext() {
      this.$debug.info('goToNext() called')
      //if no more unapproved producers, go to next step
      if (this.hasMoreUnapprovedUsers === false) {
        this.$debug.info('no more unapproved users found')
        this.$api.commissionMonthlyReview
          .completeStep({
            date: this.date,
            step: 'producers',
          })
          .then(({ data }) => {
            this.$debug.info('setting step statuses', data)
            this.setStepsStatuses(data)
            this.$debug.info('emitting next step')
            this.$emit('nextStep')
          })

        return
      }

      //if has unapproved producers, go to next producer

      this.$debug.info('has unapproved users')
      this.$router
        .push({
          name: 'commission-statement.producer',
          params: {
            year: this.$route.params.year,
            month: this.$route.params.month,
            producer: this.unapprovedUsers[0].id,
          },
        })
        .catch(() => {
        })
    },
    goToStep(step) {
      this.$debug.info('wizard emitted goToStep, relaying on producer view', step)
      this.$emit('goToStep', step)
    },
  },
  mounted() {
    this.$emit('forceActiveStep', 'producer') //for now a temp fix to force step id when coming from finalize screen

    this.$debug.info('loading statement for producer', {
      date: this.date,
      producer: this.$route.params.producer,
      currentRoute: this.$route,
    })

    this.loadProducers({
      date: this.date,
    }).then(() => {

      this.$api.commissionStatements.availablePlans({
        user: this.$route.params.producer,
        date: this.date,
      }).then(({ data }) => {
        this.$debug.info('available plans', data)
        this.$store.commit('commissions/producerStatement/setAvailablePlans', data)

        this.loadStatement({
          producerId: this.$route.params.producer,
          date: this.date,
        }).then(() => {
          this.loading.statement = false
        })
      })
    })
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
      activeDate: 'commissions/producerStatement/getActiveDate',
      activeUser: 'commissions/producerStatement/getActiveUser',
      currentStep: 'commissions/monthlyReview/getCurrentStep',
      tableData: 'commissions/producerStatement/getCommissionsTable',
    }),
    hasMoreUnapprovedUsers() {
      return (
        this.$_.filter(this.producers, user => {
          return user.id !== this.filterUser && user.approved === false
        }).length > 0
      )
    },
    unapprovedUsers() {
      return this.$_.filter(this.producers, user => {
        return user.approved === false
      })
    },
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
    year() {
      return this.$route.params.year
    },
    month() {
      return this.toMonthName(this.$route.params.month)
    },
  },
}
</script>

<style lang='scss' scoped>
#statementContentHeaderContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 2px;
}

#filterPanel {
  margin-top: 32px;
}

#tablePanel {
  margin-bottom: 2px;
}

#bonusPanel,
#totalPanel,
#subTotalPanel {
  background: #fff;
}

#subTotalPanel {
  padding: 24px 32px 0px 0px;
}

#bonusPanel {
  padding: 8px 32px 16px 18px;
}

#totalPanel {
  padding: 16px 32px 16px 32px;
}

.filterSelectTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3541;
  letter-spacing: 0.5px;
}

.dateText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #3a3541;
}
</style>
