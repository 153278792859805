<template>
  <div id='deferredPanel' v-if='deferredCommissions.length'>
    <h3 id='deferredTitle'>
      Deferred Commissions
    </h3>
    <v-simple-table>
      <thead>
      <tr>
        <th>
          Effective
        </th>
        <th>
          Type
        </th>
        <th>
          LOB
        </th>
        <th>
          Insured
        </th>
        <th>
          Policy Number
        </th>
        <th>
          Carrier
        </th>
        <th>
          Agency Pay
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for='commission in deferredCommissions'>
        <td>
          {{moment(commission.attributes.effective_date).format('MMM Do')}}
        </td>
        <td>
          {{ commission.attributes.reason.code }}
        </td>
        <td>
          {{ commission.attributes.policy_type}}
        </td>
        <td>
          {{ commission.attributes.insured_name}}
        </td>
        <td>
          {{ commission.attributes.policy_number}}
        </td>
        <td>
          {{ commission.attributes.carrier}}
        </td>
        <td>
          {{ utilities.formatCurrency(commission.attributes.final_amount) }}
        </td>
        <td class='text-end' style='width:100px;'>
          <v-btn text color='primary'  @click.prevent='deDeferCommission(commission.id)'>
            Undo
          </v-btn>
        </td>
      </tr>

      </tbody>
    </v-simple-table>

    <defer-commission-error-modal :show='deferCommissionModal' :message='deferCommissionError' @hide='deferCommissionModal = false'/>
  </div>
</template>

<script>
import moment from 'moment'
import utilities from '@/config/globals/utilities'
import DeferCommissionErrorModal
  from '@/pages/commissions/commission-statements/wizard/steps/producer/DeferCommissionErrorModal.vue'

export default {
  components: { DeferCommissionErrorModal },
  computed: {
    utilities() {
      return utilities
    },
    moment() {
      return moment
    }
  },
  props: [
    'user',
    'activeDate',
  ],
  data() {
    return {
      deferredCommissions: [],
      deferCommissionModal: false,
      deferCommissionError: '',
    }
  },
  methods: {
    loadDeferredCommissions() {
      if (!this.user) return

      this.$api.commissionStatements.deferredCommissions({
        user: this.user,
        date: this.activeDate,
      }).then((response) => {
        this.deferredCommissions = response.data
      })
    },
    deDeferCommission(id) {
      this.$api.commissions.deDefer({
        commission: id
      }).then((response) => {
        this.loadDeferredCommissions()
        this.$emit('reload')
      }).catch((error) => {
        //todo show error for defer of commission
        this.deferCommissionError = error.response.data.message
        this.deferCommissionModal = true
      })
    },
  },
  mounted() {
    this.loadDeferredCommissions()
  },
  watch: {
    user() {
      this.loadDeferredCommissions()
    },
    activeDate() {
      this.loadDeferredCommissions()
    },
  },
}
</script>

<style lang='scss' scoped>
#deferredPanel {
  margin-top: 2px;
  background: #fff;
}

#deferredTitle {
  margin-left: 16px;
  margin-bottom: 16px;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #3a3541;
}
</style>
