<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="headline">There was a problem</v-card-title>
      <v-card-text>
        <p>
          {{ message }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="danger" text @click="hide">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'show',
    'message'
  ],
  methods:{
    hide(){
      this.$emit('hide')
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
