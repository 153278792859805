<template>
  <v-select
    hide-details
    :loading="processing"
    :disabled="processing"
    :items="producers"
    :return-object="false"
    item-text="display"
    item-value="value"
    label="Assigned To"
    v-model="user"
    @change="update"
    style="margin-top:0;margin-bottom: 0;padding-top:0;"
  >
    <template v-slot:item="{ item }">
      <div>
        {{ item.display }}
      </div>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'value',
    'commission',
  ],
  data() {
    return {
      user: this.value,
      processing: false
    }
  },
  methods: {
    update() {
      this.$emit('input', this.user);

      this.$emit('reassigned', {
        commission: this.commission.id,
        user: this.user
      })
    }
  },
  computed:{
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
    })
  }
}
</script>

<style scoped>

</style>
