<template>
  <div>
    <reason-edit-select
      style="min-width:80px;"
      :commission="item"
      @change="updateCommissionReason"
      v-if="item.is_manual"
    />
    <span v-else>
      <v-tooltip top v-if='reasonCodeDescription'>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ item.type }}
          </span>
        </template>
        <span>{{ reasonCodeDescription }}</span>
      </v-tooltip>
      <span v-else>
        {{ item.type }}
      </span>
    </span>
  </div>
</template>

<script>
import ReasonEditSelect from '../ReasonEditSelect'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: { ReasonEditSelect },
  props: ['item'],
  methods: {
    updateCommissionReason({ commission, reasonCode }) {
      this.$api.commissions
        .updateReason({
          commission: commission.id,
          reason: reasonCode,
        })
        .then(({ data }) => {
          this.$debug.info('commission reason updated')
          this.setCommission(data);
          this.refreshCommissionStatement();
        })
    },
    ...mapActions({
      setCommission: 'commissions/producerStatement/setCommission',
      refreshCommissionStatement: 'commissions/producerStatement/refreshStatement',
    }),
  },
  computed: {
    ...mapGetters({
      enums: 'enums/getEnums',
    }),
    reasonCodeDescription(){
      let reason = this.enums.commission_reason_codes[this.item.type]

        if(reason) {
        return reason.display_value
        }
    }
  },
}
</script>

<style scoped></style>
